/* eslint-disable no-underscore-dangle, id-blacklist, id-match */
import { Timestamp } from '@angular/fire/firestore';

import { USER_STATUS_ACTIVE, USER_STATUS_INACTIVE } from 'src/app/constants';

export interface UserTeam {
  id: string;
  slackId?: string;
  slackHandle?: string;
  name: string;
  description?: string;
}

export interface Customer {
  id: string;
  name: string;
  slackChannelId: string;
  slackTeamId: string;
  followerCount: number;
  tags?: Tag[];
  supportSteps?: SupportStep[];
  adminEmails?: string[];
  adminIds?: string[];
  slackChannel?: SlackChannel;
  isInternal?: boolean;
  primarySupportAssigneeId?: string;
  primarySupportAssigneeType?: string;
  secondarySupportAssigneeId?: string;
  secondarySupportAssigneeType?: string;
  slackTeams: { id: string; name: string; icon?: string }[];
  externalCrmMetadata?: any;
  createdBy: string;
  createdAt: Date;
  supportStepRuleset?: any;
  supportStepRulesetId?: string;
  customFields?: any[];
}

export interface Metadata {
  creatorName: string;
  creatorPhone: string;
}

export interface Profile {
  autoAddBot?: boolean | null;
  autoAddBotSettings?: {
    condition: string;
    regex?: string;
    channelType?: string;
    isInternalOverride?: boolean | null;
  };
  deleted?: boolean;
  status?: typeof USER_STATUS_ACTIVE | typeof USER_STATUS_INACTIVE;
  deviceToken: string;
  photo?: string;
  photoPath?: string;
  phoneNumber?: string;
  email?: string;
  createdAt?: Date;
  updatedAt?: Date;
  updatedBy?: string;
  lastSeenAt?: Date;
  name?: string;
  objectId?: string;
  zoomEnabled?: boolean;
  googleCalendarEnabled?: boolean;
  pronoun?: string;
  description?: string;
  customFields?: any[];
  tenantId?: string;
  additionalIdpFields?: Record<string, unknown>;
  id?: string;
  title?: string;
  organization?: string;
  division?: string;
  costCenter?: string;
  employeeNumber?: string;
  employeeType?: string;
  department?: string;
  streetAddress?: string;
  country?: string;
  region?: string;
  locality?: string;
  postalCode?: string;
  slackId?: string;
  isExternal?: boolean;
  widgetVerificationHash?: string;
  helpCenterVerificationHash?: string;
  awayUntil?: Date;
  backupUserId?: string;
  isAutoAssignable?: boolean | null;
  emailSignature?: string | null;
  notificationSettings?: {
    conversationAssignedToMe?: boolean;
    conversationCreated?: boolean;
  } | null;
  slackInboxState?: {
    sort?: string;
    currentButtonId?: string;
  };
  permissions?: {
    viewReporting?: boolean;
  };
  isAdmin?: boolean;
  manager?: {
    displayName: string;
  };
  slackTeam?: {
    id: string;
    name: string;
  };
}

export class Upload {
  $key: string;
  file: File;
  path: string;
  name: string;
  url: string;
  progress: number;
  createdAt: Date = new Date();
  metadata?: {
    uploadId: string;
    tenantId: string;
    createdBy: string;
    filename: string;
    status: string;
    type: string;
    objectId: string;
    muteWelcomeEmail?: string; // firebase storage only wants strings
  };

  constructor(file: File) {
    this.file = file;
  }
}

export const AUTH_PROVIDER_PASSWORD = 'password';
export const AUTH_PROVIDER_EMAIL_LINK = 'emailLink';
export const AUTH_PROVIDER_GSUITE = 'gsuite';
export const AUTH_PROVIDER_OKTA_OIDC = 'okta-oidc';
export const AUTH_PROVIDER_OKTA_SAML = 'okta-saml';
export const AUTH_PROVIDER_PHONE = 'phone';
export const AUTH_PROVIDER_MICROSOFT = 'microsoft.com';

export type AuthProvider =
  | typeof AUTH_PROVIDER_PASSWORD
  | typeof AUTH_PROVIDER_GSUITE
  | typeof AUTH_PROVIDER_OKTA_OIDC
  | typeof AUTH_PROVIDER_OKTA_SAML
  | typeof AUTH_PROVIDER_PHONE
  | typeof AUTH_PROVIDER_MICROSOFT;

export interface WebhookSubscription {
  id: string;
  url: string;
  enabled: boolean;
  consecutiveFailures: number;
}

export interface WebhookConfig {
  secret: string;
}

export interface AiFeatures {
  autoTagging?: boolean;
  autoTitle?: boolean;
  autoTicketType?: boolean;
  autoSummarize?: boolean;
  postSimilarConversations?: boolean;
  suggestResponses?: boolean;
  autoPrioritize?: boolean;
  customerSentiment?: boolean;
}

export class Tenant {
  disableAutomatedTicketing: boolean;
  closeConversationsAfterHours: number | null;
  closeStaleConversationsMode: string;
  assignToTaggedUserEnabled: boolean;
  subdomain: string;
  slackBotId?: string;
  slackBotBotId?: string;
  slackTeamId?: string;
  slackTeamName?: string;
  slackTeamUrl?: string;
  workos?: any;
  id?: string;
  adminEmails: string[];
  name: string;
  isPublic: boolean;
  logo: string;
  primaryColor: string;
  provider: string;
  authProviders?: AuthProvider[];
  email?: string;
  phone?: string;
  allowedEmailDomains: string[];
  permissions: {
    canCreatePlans?: boolean;
    canCreateOrganizers?: boolean;
    canCreatePosts?: boolean;
  };
  users: {
    phoneNumberRequired?: boolean;
    customProfileFields?: any[];
  };
  defaultFieldSettings?: {
    [id: string]: {
      public?: boolean;
    };
  };
  homePageFilters?: string[];
  accountStatus?: string;
  integrations?: any;
  defaultCommunityId?: string;
  trialEndDate: any;
  isInternal: boolean;
  defaultSupportSteps: any[];
  defaultTriageChannelId?: string;
  autoresponder: {
    enabled: boolean;
    condition: string;
    message: string;
    includeKnowledgeBaseLinks?: string;
    includeAiResponse?: string;
    alternateMessage?: string;
    aiResponsePrefix?: string;
    aiResponseFallback?: string;
  };
  workingHours: {
    enabled: boolean;
    intervals: any[];
    timezone?: string;
  };
  billing?: {
    stripeProductId?: string;
    stripePriceId?: string;
    stripeSubscriptionId?: string;
    restrictedFeatures?: string[];
    upgradeProductId?: string;
    upgradePriceId?: string;
  };
  teamAssignmentLoadBalancingMethod?: string;
  skippedOnboardingSteps?: Record<string, boolean>;
  replyTimeoutMinutes?: number;
  botHandling?: string;
  isUserAutoAssignableDefault: boolean;
  slackAwaySettings: {
    useStatus: boolean;
    useDnd: boolean;
    awayEmojis: string[];
  };
  aiFeatures: AiFeatures;
  supportName?: string;
  supportLogo?: string;
  externalCrmConfig?: any;
  externalTaskManagementConfig?: {
    siteUrl?: string;
    orgId?: string;
    name?: string;
    type?: string;
    projectKeys?: string[];
  };
  customerViewEnabled: boolean;
  emojiActionsConfig: Record<string, string>;
  moveToNeedsResponse?: boolean;
  autoSplittingEnabled?: boolean;
  doNotReopenConversationsOnReply?: boolean;
  disableConversationsStartedByInternalUser?: boolean;
  advancedInternalTicketingEnabled?: boolean;
  advancedInternalTicketingDefaultCustomerId?: string;
  customEmojis: Record<string, string>;
  isCustomActionsEnabled?: boolean;
  postCloseSettings?: {
    enabled?: boolean;
    slackTemplateId?: string | null;
    ticketTypeIds?: string[];
  };
  customCustomerFields?: any[];
  isInternalSupport: boolean;
  restrictManualTicketingToAgents: boolean;
  defaultTicketTypeId?: string;
  interactiveModeCustomPrompt?: string;
  interactiveModeVersion?: number;
  defaultToPublicMessages?: boolean;
  llmConfig?: {
    type: 'google' | 'openai';
    openai?: {
      advancedKnowledgeBaseFiltering?: boolean;
    };
  };
  doesAclApplyToAdmins?: boolean;
  restrictConversationAccess?: boolean;
}

interface SupportStep {
  type: 'assignment' | 'escalation' | 'reminder' | 'triage';
  userId?: string;
  assigneeId?: string;
  assigneeType: 'user' | 'team';
  minutes: number;
}

export class Organizer {
  isInteractiveMode?: boolean;
  conversationCreationConditions?: any[];
  name: string;
  tagline: string;
  description: string;
  category?: string;
  createdBy: string;
  updatedBy?: string;
  objectId?: string;
  image: string;
  tenantId?: string;
  createdAt: Date;
  updatedAt: Date;
  adminEmails: string[];
  adminIds?: string[];
  admins: string[];
  calendarUrl: string;
  email: string;
  slackChannelId?: string;
  slackChannel?: SlackChannel;
  followerCount: number;
  isPublic: boolean;
  isDiscoverable: boolean;
  showMemberList: string;
  newMemberNotification: string;
  newMemberNotificationOptOutList: string[];
  newMemberWelcomeEmailTemplate?: string;
  eventbriteFeedUrl?: string;
  eventbriteId?: string;
  externalCalendar?: {
    id?: string;
    sync?: string;
    source?: 'microsoft.com' | 'google.com';
    name?: string;
    userId?: string;
  };
  slackEnabled?: boolean;
  teamsEnabled?: boolean;
  syncSlackChannel?: boolean;
  teamsWebhookUrl?: string;
  memberSourceType?: string;
  removed?: boolean;
  customFields?: any[];
  id?: string;
  defaultSettingsEventId?: string;
  supportSteps?: SupportStep[];
  slackTeamId?: string;
  slackTeamName?: string;
  tags?: Tag[];
  replyTimeoutMinutes?: number;
  defaultTriageChannelId?: string;
  disableAutomatedTicketing?: boolean;
  primarySupportAssigneeId?: string;
  primarySupportAssigneeType?: string;
  secondarySupportAssigneeId?: string;
  secondarySupportAssigneeType?: string;
  botHandling?: string;
  autoresponder?: any;
  assignToTaggedUserEnabled?: boolean;
  emailDomains?: string[] | null;
  emailDomainsWrapper?: {
    emailDomains: string[];
  };
  isInternal?: boolean;
  externalCrmMetadata?: any;
  supportStepRuleset?: any;
  supportStepRulesetId?: string;
  customerViewEnabled: boolean | null;
}

export interface OrganizerFollow {
  organizerId: string;
  customFields?: Record<string, unknown>;
  following?: boolean;
  userId?: string;
  name?: string;
  email?: string;
  phoneNumber?: string;
}

export interface CommunityMember {
  communityId: string;
  userId: string;
  following: boolean;
  customFields?: Record<string, unknown>;
  tenantId: string | null;
  user?: Profile;
  isExternal?: boolean;
}

export interface UserTag {
  id: string;
  name: string;
  communityId: string;
  rule: any;
}

export interface Tag {
  id: string;
  name: string;
  description: string;
  autoTaggingEnabled: boolean;
}

export interface TicketType {
  id: string;
  name: string;
  description: string;
  fields: any[];
  defaultCustomerId?: string;
  defaultSlackChannelId?: string;
}

export interface CollaboratorType {
  id: string;
  name: string;
  description: string;
  key: string;
}

export class Template {
  id: string;
  name: string;
  userId: string;
  createdAt: Date;
  updatedAt: Date;
  type: string;
  chunks?: any;
  design?: any;
  html: string;
  communityId?: string;
  tenantId: string;
  isDiscoverable: boolean;
  image?: string;
  createdBy?: string;
}

export class UserNotification {
  createdAt: Timestamp;
  userId: string;
  tenantId: string;
  read: boolean;
  readAt?: Timestamp;
  image?: string;
  type: string;
  data?: any;
  objectId?: string;
}

export class UnsplashPhoto {
  id: string;
  alt_description: string;
  blur_hash: string;
  categories: string[];
  color: string;
  crearedAt: string;
  current_user_collections: any[];
  description: string;
  height: number;
  liked_by_user: boolean;
  likes: number;
  links: any;
  promoted_at: string;
  sponsorship: any;
  updated_at: string;
  urls: any;
  user: any;
  width: number;
}

export interface ListQueryResult {
  rows: any[];
  count: number;
}

export interface Integration {
  imageSrc: string;
  name: string;
  title: string;
  connect: string;
  preview: string;
}

export interface IntegrationCatalog {
  imageSrc: string;
  url: string;
  key: string;
  name: string;
  title: string;
  bullets: string[];
  html: string;
  connect: string;
  unthreadPreview: string;
}

export interface WhereClause {
  field: string;
  operator: '<' | '<=' | '==' | '!=' | '>=' | '>' | 'array-contains' | 'in' | 'array-contains-any' | 'not-in' | 'like';
  value: any;
}

export interface ListRequest {
  where?: WhereClause[];
  order?: string[];
  select?: string[];
  limit?: number | null;
  descending?: boolean;
  nextCursor?: string;
  previousCursor?: string;
  [key: string]: any;
}

export interface PgWhereClause {
  field: string;
  operator: '==' | '!=' | '<' | '>' | '<=' | '>=' | 'like';
  value: any;
}

export interface PgListRequest {
  where?: PgWhereClause[];
  order?: string[];
  select?: string[];
  limit?: number | null;
  descending?: boolean;
  cursor?: string;
}

export interface ListResponse<T> {
  data: T[];
  totalCount: number;
  cursors: {
    next?: string;
    previous?: string;
    hasNext: boolean;
    hasPrevious: boolean;
  };
}

export interface Outbound {
  id: string;
  tenantId: string;
  deliveryMethod: String;
  runAt?: Date;
  subject?: string;
  blocks?: any[];
  recipients?: any[];
  communityId?: string;
  sendAs?: any;
  sendToSlackChannel: boolean;
  status: string;
  metadata?: any;
}

export interface ConversationCollaborator {
  conversationId?: string;
  userId?: string;
  groupId?: string;
  entityId?: string;
  tenantId?: string;
  createdAt?: Date;
  updatedAt?: Date;
  user?: Profile;
  group?: UserTeam;
  collaboratorTypeId?: string;
  collaboratorType?: CollaboratorType;
}

export interface ConversationFollower {
  conversationId?: string;
  userId?: string;
  groupId?: string;
  entity?: string;
  tenantId?: string;
  createdAt?: Date;
  updatedAt?: Date;
  user?: Profile;
  group?: UserTeam;
}

export interface Conversation {
  tenantId: string;
  createdAt: Date;
  updatedAt: Date;
  teamId: string;
  channelId: string;
  messageTs: string;
  initialMessage: any;
  latestMessage: any;
  status: string;
  threadTs: string;
  friendlyId: string;
  customerId: string;
  assignedToUserId: string;
  assignedToUser?: Profile;
  id: string;
  wasManuallyCreated?: boolean;
  category: string;
  respondedAt?: Date;
  responseTime?: number;
  responseTimeWorking?: number;
  closedAt?: Date;
  resolutionTime?: number;
  resolutionTimeWorking?: number;
  notes?: string;
  dueDate?: Date;
  priority?: number;
  wakeUpAt?: Date;
  snoozedAt?: Date;
  tags?: Tag[];
  excludeAnalytics?: boolean;
  customer?: {
    name: string;
    slackChannel?: {
      name?: string;
    };
    tags: { id: string; name: string }[];
    customFields?: any[];
  };
  summary?: string;
  triageMessages?: any[];
  escalationTier?: number;
  title?: string;
  resolvedTitle?: any[];
  slackChannel?: {
    id: string;
    name: string;
    teamId?: string;
  };
  externalTasks?: { id: string; name: string; remoteId: string; friendlyId?: string; isCompleted: boolean }[];
  externalSupportMetadata?: { url: string; type: string };
  statusUpdatedAt?: Date;
  latestTopLevelTs?: string;
  lockedAt?: Date;
  latestExternalMessage?: {
    id: string;
    timestamp: Date;
  };
  latestInternalMessage?: {
    id: string;
    timestamp: Date;
  };
  ticketTypeId?: string;
  ticketType?: {
    id: string;
    name: string;
    description: string;
    fields: any[];
  };
  ticketTypeFields?: any;
  titleOverwritten?: boolean;
  initialMessageId?: string;
  sentiment?: number;
  language?: string;
  collaborators?: ConversationCollaborator[];
  followers?: ConversationFollower[];
  submitterUser?: {
    name?: string;
    email?: string;
    photo?: string;
  };
  inboundEmailRuleId?: string;
}

export interface ConversationMessage {
  id: string;
  content: string;
  resolvedContent: (string | any)[];
  tenantId: string;
  timestamp: Date;
  conversationId: string;
  ts: string;
  subtype?: string;
  user?: any;
  inbound?: any;
  botId?: string;
  isAutoresponse?: boolean;
  emailId?: string;
  nlpResult?: any;
  userType?: string;
  text?: string;
  blocks?: any[];
  triageThread?: {
    id: string;
    initialMessage: {
      id: string;
      ts: string;
      slackChannel: {
        id: string;
        name: string;
      };
    };
  };
  slackChannel?: {
    id: string;
    name: string;
  };
  widgetMessageId?: string;
  microsoftTeamsMessageId?: string;
  isPrivateExplanation?: boolean;
  files?: any[];
  survey?: any;
  sourceType?: string;
  deletedAt?: string;
  reactions?: {
    name: string;
    count: number;
    users: string[];
  }[];
  channelId: string;
  isPrivateNote?: boolean;
  manualSplitMessageId?: string;
}

export interface ServiceAccount {
  id: string;
  name: string;
  status: string;
  tenantId: string;
  createdAt: Date;
  crreatedBy: string;
  updatedAt: Date;
  updatedBy: string;
  token?: string;
}

export interface InboundEmail {
  id: string;
  fromUser?: {
    id: string;
    name: string;
    email: string;
  };
  inboundEmailRuleId: string;
  ccs: any[];
  tos: any[];
  createdAt: Date;
  subject: string;
  htmlContent: string;
  attachments: any[];
  inbound: any;
}

export interface InboundEmailRule {
  id: string;
  tenantId: string | null;
  email: string;
  channelId: string;
  customerId: string;
  createdBy: string | null;
  updatedBy: string | null;
  updatedAt: Date;
  createdAt: Date;
  supportSteps?: any[];
  name?: string;
  slackChannel: string;
  customer?: {
    name: string;
  };
  autoresponder?: {
    enabled: boolean;
    condition: string;
    message: string;
  };
  blocklist?: string[];
  shortId?: string;
  alwaysUseGenericEmailAddress?: boolean;
  isExternalSupport: boolean;
}

export interface ExternalChatConnection {
  id: string;
  tenantId: string | null;
  type: string;
  channelId: string;
  customerId: string;
  createdBy: string | null;
  updatedBy: string | null;
  updatedAt: Date;
  createdAt: Date;
  supportSteps?: any[];
  name?: string;
  slackChannel: string;
  customer?: {
    name: string;
  };
  autoresponder?: {
    enabled: boolean;
    condition: string;
    message: string;
  };
  oauthId: string;
  channelAllowlistEnabled?: boolean;
  channelAllowlist?: string[];
  providerConfig?: any;
  enabled?: boolean;
}

export interface Widget {
  id: string;
  tenantId: string | null;
  channelId: string;
  customerId: string;
  createdBy: string | null;
  updatedBy: string | null;
  updatedAt: Date;
  createdAt: Date;
  supportSteps?: any[];
  name: string;
  title?: string;
  tagline?: string;
  domains?: string[];
  color?: string;
  bubbleStyle?: string;
  bubblePosition?: string;
  replyTime?: string;
  verificationSecret: string;
  slackChannel?: SlackChannel;
  autoresponder?: {
    enabled: boolean;
    condition: string;
    message: string;
  };
  linkedInboundEmailRuleId?: string;
  isFullPageView?: boolean;
}

export interface OnboardingProgress {
  isSlackConnected: boolean;
  isCustomerAdded: boolean;
  isAssignmentRuleSet: boolean;
  isTriageChannelSet: boolean;
  isAdminAdded: boolean;
  isEmailConnected: boolean;
  isWidgetConnected: boolean;
  isIntegrationAdded: boolean;
  isTaskManagerConnected: boolean;
  isCRMConnected: boolean;
}

export interface SlackSurvey {
  buttonLabel: string;
  type: 'nps' | 'csat';
  buttonLayout: 'inline' | 'single';
  postSubmissionMessage: string;
}

export interface SlackChannel {
  id: string;
  name: string;
  isPrivate?: boolean;
  isShared?: boolean;
  displayName?: string;
  teamId: string;
}

export interface KnowledgeBaseArticle {
  id: string;
  tenantId: string;
  title: string;
  content: string;
  url?: string;
  status: string;
  createdAt: Date;
  updatedAt: Date;
  isInternal: boolean;
}

export interface KnowledgeBaseScrapeJob {
  id: string;
  tenantId: string;
  startUrl: string;
  urlAllowlist: string[];
  urlBlocklist: string[];
  isRecursive: boolean;
  createdAt: Date;
  updatedAt: Date;
  lastRunAt?: Date;
  isInternal: boolean;
  apifyRunStatus: string;
  sourceType: string;
  sourceTypeConfig: any;
  result: any;
}

export interface RunQueryResult {
  response?: string;
  articles?: { id: string; url: string; title: string }[];
  reasoningSteps?: string[];
  ticketDetails?: any;
}

export interface ExternalCrmAccount {
  id: string;
  name: string;
  slackChannelId?: string;
}

export interface ExternalTask {
  id: string;
  name: string;
  status: string;
  url: string;
  remoteId: string;
  projectId: string;
}

export interface Automation {
  id: string;
  name: string;
  description: string;
  version: number;
  createdAt: Date;
  updatedAt: Date;
  createdBy: string;
  updatedBy: string;
  status: string;
  trigger: AutomationTrigger;
  automationActions: AutomationAction[];
  inputFields: any[];
}

export type AutomationTriggerFilterOperator =
  | 'equals'
  | 'notEquals'
  | 'in'
  | 'notIn'
  | 'contains'
  | 'notContains'
  | 'greaterThan'
  | 'lessThan'
  | 'greaterThanOrEquals'
  | 'lessThanOrEquals';

export type AutomationTriggerFilter =
  // Single field comparison
  | {
      leftValue?: string;
      leftValueFrom?: string;
      operator: AutomationTriggerFilterOperator;
      rightValue?: any;
      rightValueFrom?: string;
    }
  // Logical combinations (AND/OR)
  | {
      and: AutomationTriggerFilter[];
    }
  | {
      or: AutomationTriggerFilter[];
    };

export interface AutomationTriggerEvent {
  type: 'event';
  object: string;
  event: string;
  filter?: AutomationTriggerFilter;
}

export interface AutomationTriggerButton {
  type: 'button';
  object: string;
  value: string;
}

export interface AutomationTriggerSchedule {
  type: 'schedule';
  cron: string;
  timezone: string;
}

export interface AutomationTriggerWebhook {
  type: 'webhook';
  filter?: AutomationTriggerFilter;
}

export type AutomationTrigger =
  | AutomationTriggerEvent
  | AutomationTriggerSchedule
  | AutomationTriggerButton
  | AutomationTriggerWebhook;

export interface CustomAction {
  id?: string;
  name: string;
  description?: string;
  version?: number;
  createdAt?: Date;
  updatedAt?: Date;
  createdBy?: string;
  updatedBy?: string;
  code: string;
}

export interface UnthreadAction {
  id: string;
  // ...
}

interface BaseAutomationAction {
  id?: string;
  automationId?: string;
  step?: number;
}

export interface AutomationCustomAction extends BaseAutomationAction {
  type: 'custom';
  customAction: CustomAction;
}

// TODO: This is a placeholder. We need to define the actual action types. The idea here is to be able to take a specific action in Unthread such as change converstaion status, sending an outbound, etc.
export interface AutomationUnthreadAction extends BaseAutomationAction {
  type: 'unthread';
}

export type AutomationAction = AutomationCustomAction | AutomationUnthreadAction;

export interface AutomationRun {
  id: string;
  automationId: string;
  status: string;
  startedAt: Date;
  state?: any;
  input?: any;
  completedAt: Date;
  automation?: Partial<Automation>;
}

export interface SavedReportDateRangeAbsolute {
  type: 'absolute';
  startDate: string;
  endDate: string;
}

export interface SavedReportDateRangeRelative {
  type: 'relative';
  period: 'day' | 'week' | 'month' | 'quarter' | 'year';
  startIndex: number;
  endIndex: number;
}

export type SavedReportDateRange = SavedReportDateRangeAbsolute | SavedReportDateRangeRelative;

export interface SavedReport {
  id: string;
  tenantId: string;
  name: string;
  userIds: string[] | null;
  filters?: Record<string, string[]>;
  dimensions?: string[];
  metric: string;
  dateDimension: string;
  displaySettings?: {
    unitConversion?: number;
    lineSmoothing?: boolean;
  };
  dateRange: SavedReportDateRange;
  createdBy: string;
  updatedBy: string;
  updatedAt: Date;
  createdAt: Date;
}

export interface ApprovalRequest {
  status: 'approved' | 'rejected' | 'pending';
  id: string;
  tenantId: string;
  assignedToGroupId?: string;
  assignedToUserId?: string;
  statusChangedAt?: Date;
  conversationId: string;
  title?: string;
  notes?: string;
  createdAt: Date;
  approverUser?: Profile;
  approverGroup?: UserTeam;
}
